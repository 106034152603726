import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {Button, Col, Row} from "antd";
import IntlMessage from "../../../components/util-components/IntlMessage";
import {LeftSquareOutlined} from "@ant-design/icons";
import {handleErrorApi} from "../../../serviceHandler/SlackErrorHandler";

const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();

    const handleError = (error, type) => {
            handleErrorApi(error, type);
            setHasError(true);
    };

    useEffect(() => {
        const handleGlobalError = (event) => {
            console.error('Global error caught:', event.error);
            handleError(event, 'error');
        };

        const handleUnhandledRejection = (event) => {
            console.error('Unhandled promise rejection:', event);
            handleError(event, 'unhandledrejection');
        };

        window.addEventListener('error', handleGlobalError);
        window.addEventListener('unhandledrejection', handleUnhandledRejection);

        return () => {
            window.removeEventListener('error', handleGlobalError);
            window.removeEventListener('unhandledrejection', handleUnhandledRejection);
        };
    }, [navigate]);

    if (hasError) {
        return <Error />;
    }

    return <>{children}</>;
};

////Privremeno rješenje
const Error = () => {
    const theme = useSelector(state => state.theme.currentTheme)
    return (
        <div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
            <div>
                <br/><br/>
            </div>
            <div className="container">
                <Row align="middle">
                    <Col xs={24} sm={24} md={8}>
                        <h1 className="font-weight-bold mb-4 display-4"><IntlMessage id={'general.error.pageNotFound'}/>
                        </h1>
                        <p className="font-size-md mb-4"><IntlMessage id={'general.error.text'}/></p>
                        <Link to='/app'>
                            <Button type="none" className="our-button-color-cancel" style={{marginLeft: '10px'}} onClick={() => window.location.href = '/app'}>
                                <LeftSquareOutlined /> <IntlMessage id={'form.cancel'}/>
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={24} sm={24} md={{span: 14, offset: 2}}>
                        <img className="img-fluid mt-md-0 mt-4" src="/img/others/img-20.png" alt=""/>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ErrorBoundary;
