import SlackErrorService from "../services/SlackErrorService";

const handleErrorApi = async (error, type) => {
    if (type === 'unhandledrejection') {
        return SlackErrorService.sendError({
            name: error.type,
            fileName: 'Nema ove informacije',
            stack: error.reason.stack,
            message: error.reason.message
        });
    } else if (type === 'error') {
        return SlackErrorService.sendError({
            name: error.error.message,
            fileName: error.filename,
            stack: error.error.stack,
            message: error.message
        });
    }
}

export {
    handleErrorApi
};